import {
  Countries,
  Genders,
  InviteStatus,
  Nationalities,
  SchoolEducationSystem,
  SchoolPropertyType,
  SchoolType,
  SchoolUserRole,
} from '@schooly/constants';
import { encodeBase64 } from '@schooly/utils/encode-base64';
import { updateCancelToken } from '@schooly/utils/update-cancel-token';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { AttendanceCode } from './apiTypes/attendance';
import { AssessmentsGradeUpdate } from './apiTypes/endpoints/assessments';
import { ConductTypeCreate } from './apiTypes/endpoints/conduct';
import {
  AllSearchResult,
  IColumnSort,
  ListPeopleArguments,
  ListStudentsByParentsArguments,
  MiniListArguments,
  ParentSearchResult,
  SimpleListResult,
  StaffSearchResult,
  StudentSearchResult,
} from './apiTypes/endpoints/people';
import {
  ChangeDefaultValuesForPropertiesProps,
  CreateDepartmentsRequest,
  CreateDepartmentsResponse,
  GetEligibleRelationsProps,
  GetSchoolPropertiesProps,
  GetSchoolPropertiesResponse,
  MarkStudentsNotDuplicateRequest,
  MergeStudentsRequest,
  SchoolCreateProps,
  UpdateAgeGroupsRequest,
  UpdateAgeGroupsResponse,
  UpdateDepartmentsRequest,
  UpdateDepartmentsResponse,
  UpdateHousesProps,
  UpdateSchoolYearPeriodsRequest,
} from './apiTypes/endpoints/school';
import { GroupCategory, GroupSubject } from './apiTypes/groups';
import { Invite, InviteStatusData } from './apiTypes/invites';
import { LeavingReason, LeavingReasonType } from './apiTypes/leavingReasons';
import { ApiError, PagedResponse, SchoolUserType, SORT_DIRECTION, UserType } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import {
  StaffRegistration,
  StaffRegistrationUpdate,
  StudentRegistration,
  StudentRegistrationUpdate,
} from './apiTypes/registrations';
import {
  ParentSchoolRelation,
  SchoolRelation,
  StaffSchoolRelation,
  StatusStudentRelation,
  StatusUserRelation,
  StudentSchoolRelation,
} from './apiTypes/relations';
import {
  AgeGroup,
  DemoSchoolType,
  School,
  SchoolLevel,
  SchoolProperty,
  SchoolPropertyCategory,
  SchoolYear,
  SchoolYearUpdate,
} from './apiTypes/schools';
import { UserRole } from './apiTypes/userRoles';
import {
  DuplicatesForUser,
  FilterKeys,
  UserForDuplicatesCheck,
  UserSummary,
} from './apiTypes/users';
import * as api from './requests';
import { cachedRequest } from './utils/cachedRequest';
import { getFormattedParams } from './utils/getFormattedParams';
import { getSortParams } from './utils/getSortParam';
import { removeObjectEmptyArrays } from './utils/removeObjectEmptyArrays';
import { removeObjectFalsyValues } from './utils/removeObjectFalsyValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

function convertSchoolUserRoleToNumericType(type: SchoolUserType): SchoolUserRole {
  switch (type) {
    case 'student':
      return SchoolUserRole.Student;
    case 'parent':
      return SchoolUserRole.Parent;
    case 'staff':
    default:
      return SchoolUserRole.Staff;
  }
}

const DEFAULT_PAGE_SIZE = 50;

const SCHOOL_URL = '/school/';
const SCHOOL_DETAILS_URL = '/school_details/';
const USERCOUNT_URL = '/user-count/';

export type SchoolUserCountTypes =
  | SchoolUserType
  | 'non_member_adults'
  | 'non_member_children'
  | 'group'
  | 'assessment'
  | 'report'
  | 'application'
  | 'legal_entities';

export const getSchool = (schoolId: string): Promise<School> => {
  return api.get(`${SCHOOL_URL}${schoolId}`);
};

export const GET_SCHOOL_QUERY = `${SCHOOL_URL}GET_SCHOOL_QUERY`;

export const useGetSchoolQuery = (schoolId: string, options?: RQUseQueryOptions<School>) => {
  return useQuery<School, ApiError>(
    [GET_SCHOOL_QUERY, schoolId],
    () => getSchool(schoolId),
    options,
  );
};

export const getSchoolPublic = (schoolId: string): Promise<Pick<School, 'code' | 'name'>> => {
  return api.get(`${SCHOOL_URL}${schoolId}/public`);
};

export type CreateSchoolResponse = {
  id: string;
};

export const createSchool = (props: SchoolCreateProps) => {
  return api.post(`${SCHOOL_URL}create-school`, props);
};

export const useCreateSchoolMutation = (
  options?: RQUseMutationOptions<CreateSchoolResponse, SchoolCreateProps>,
): RQUseMutationResult<CreateSchoolResponse, SchoolCreateProps> => {
  return useMutation((params: SchoolCreateProps) => createSchool(params), {
    ...options,
  });
};

type RequestSchoolVerificationResponse = { success: boolean };

export const requestSchoolVerification = (
  schoolId: string,
): Promise<RequestSchoolVerificationResponse> => {
  return api.post(`${SCHOOL_URL}${schoolId}/verify-school`, {});
};

export const useRequestSchoolVerificationMutation = (
  options?: RQUseMutationOptions<RequestSchoolVerificationResponse, string>,
): RQUseMutationResult<RequestSchoolVerificationResponse, string> => {
  return useMutation(requestSchoolVerification, {
    ...options,
  });
};

export type UpdateSchoolPrarms = Partial<Omit<School, 'id'>> & Partial<SchoolCreateProps>;

export const updateSchool = (schoolId: string, props: UpdateSchoolPrarms): Promise<School> => {
  return api.patch(`${SCHOOL_URL}edit-school`, {
    school_id: schoolId,
    ...props,
  });
};

export const useUpdateSchoolMutation = (
  options?: RQUseMutationOptions<School, UpdateSchoolPrarms & { school_id: string }>,
): RQUseMutationResult<School, UpdateSchoolPrarms & { school_id: string }> => {
  return useMutation(
    (params: UpdateSchoolPrarms & { school_id: string }) => updateSchool(params.school_id, params),
    {
      ...options,
    },
  );
};

type DeleteSchoolResponse = { success: string };

export const deleteSchool = (schoolId: string): Promise<DeleteSchoolResponse> => {
  return api.remove(`${SCHOOL_URL}delete-school/${schoolId}`);
};

export const DELETE_SCHOOL = `${SCHOOL_URL}DELETE_SCHOOL`;

export const useDeleteSchoolMutation = (
  options?: RQUseMutationOptions<DeleteSchoolResponse, string>,
): RQUseMutationResult<DeleteSchoolResponse, string> => {
  return useMutation([DELETE_SCHOOL], deleteSchool, {
    ...options,
  });
};

type GetSchoolUserCountsResponse = Record<SchoolUserCountTypes, number>;

export async function getSchoolUserCount(schoolId: string): Promise<GetSchoolUserCountsResponse> {
  return api.get(`${SCHOOL_URL}${schoolId}${USERCOUNT_URL}`);
}

export const GET_SCHOOL_USER_COUNTS = `${SCHOOL_URL}GET_SCHOOL_USER_COUNTS`;

export const useGetSchoolUserCountsQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolUserCountsResponse>,
) => {
  return useQuery<GetSchoolUserCountsResponse, ApiError>(
    [GET_SCHOOL_USER_COUNTS, schoolId],
    () => getSchoolUserCount(schoolId),
    options,
  );
};

export type GetCategoriesResponse = { categories: SchoolPropertyCategory[] };

export const getCategories = (): Promise<GetCategoriesResponse> => {
  return api.get(`${SCHOOL_URL}get-categories`);
};

export const GET_CATEGORIES_QUERY = `${SCHOOL_URL}GET_CATEGORIES_QUERY`;

export const useGetCategoriesQuery = (options?: RQUseQueryOptions<GetCategoriesResponse>) => {
  return useQuery<GetCategoriesResponse, ApiError>([GET_CATEGORIES_QUERY], getCategories, options);
};

export function listPeople<T extends AllSearchResult>({
  schoolId,
  type,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  query,
  date,
  filters,
  token,
  sort = [{ columnTextId: 'last_name', direction: SORT_DIRECTION.ASC }],
  arrangeBy,
  showEditable,
}: ListPeopleArguments): Promise<PagedResponse<T>> {
  const sortBy: IColumnSort[] = [];
  const sortStudentsBy: IColumnSort[] = [];

  if (arrangeBy && type !== 'parent') {
    sortBy.push({ columnTextId: arrangeBy, direction: SORT_DIRECTION.ASC });
  }

  sort?.forEach((item) => {
    let sortType = sortBy;
    let columnTextId = item.columnTextId;
    const direction = item.direction;

    switch (columnTextId) {
      case 'students':
      case 'edit_access':
        sortType = sortStudentsBy;
        break;
    }

    if (columnTextId === 'students') {
      columnTextId = 'last_name';
    }

    sortType.push({ columnTextId, direction });

    if (columnTextId === 'last_name') {
      sortType.push({ columnTextId: 'given_name', direction });
    }
  });

  const params = getFormattedParams(
    {
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: sortBy.length > 0 ? getSortParams(sortBy) : undefined,
      sort_students_by: sortStudentsBy.length > 0 ? getSortParams(sortStudentsBy) : undefined,
      query,
      date,
      show_editable: showEditable ? 1 : undefined,
    },
    filters,
  );

  const typeUrl = type === 'staff' ? type : `${type}s`;

  return api.get(`${SCHOOL_URL}${schoolId}/${typeUrl}`, {
    params,
    cancelToken: token,
  });
}

export const STUDENTS_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Status,
  FilterKeys.LeavingReason,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Gender,
  FilterKeys.Nationality,
  FilterKeys.Expired,
] as const;

export type GetStudentsQueryFilters = {
  [FilterKeys.Date]?: [string];
  [FilterKeys.LeavingReason]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.Nationality]?: Nationalities[];
  [FilterKeys.Gender]?: Genders[];
  [FilterKeys.Status]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.TutorGroup]?: string[];
  [FilterKeys.Expired]?: number[];
};

export type StudentsArrangeBy =
  | FilterKeys.Status
  | FilterKeys.LeavingReason
  | FilterKeys.AgeGroup
  | FilterKeys.House
  | FilterKeys.Gender
  | FilterKeys.Nationality
  | FilterKeys.TutorGroup;

export const STUDENTS_ARRANGE_BY_FILTER_KEYS = [
  FilterKeys.Status,
  FilterKeys.LeavingReason,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Gender,
  FilterKeys.Nationality,
  FilterKeys.TutorGroup,
] as const;

export type GetStudentsQuerySort = {
  columnTextId: 'last_name' | 'gender' | 'age_group' | 'house' | 'status';
  direction: SORT_DIRECTION;
};

export const GET_STUDENTS_QUERY = `${SCHOOL_URL}GET_STUDENTS_QUERY`;

export const useGetStudentsQuery = (
  initialParams: Omit<ListPeopleArguments, 'filters' | 'type' | 'sort'> & {
    filters: GetStudentsQueryFilters;
    sort?: GetStudentsQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentSearchResult>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<StudentSearchResult>, ApiError>(
    [GET_STUDENTS_QUERY, params],
    ({ pageParam }) =>
      listPeople({
        ...params,
        sort: params.sort ? [params.sort] : undefined,
        pageNumber: pageParam,
        type: 'student',
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const STAFF_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.AgeGroup,
  FilterKeys.Nationality,
  FilterKeys.Subject,
  FilterKeys.Gender,
  FilterKeys.Status,
  FilterKeys.House,
  FilterKeys.Department,
] as const;

export type GetStaffQueryFilters = {
  [FilterKeys.Date]?: [string];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.Nationality]?: Nationalities[];
  [FilterKeys.Subject]?: string[];
  [FilterKeys.Gender]?: Genders[];
  [FilterKeys.Status]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Department]?: string[];
};

export type StaffArrangeBy =
  | FilterKeys.Status
  | FilterKeys.House
  | FilterKeys.AgeGroup
  | FilterKeys.Nationality
  | FilterKeys.Gender
  | FilterKeys.Department;

export const STAFF_ARRANGE_BY_FILTER_KEYS = [
  FilterKeys.Status,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Gender,
  FilterKeys.Nationality,
  FilterKeys.Department,
] as const;

export type GetStaffQuerySort = {
  columnTextId: 'last_name' | 'gender' | 'job_title' | 'status';
  direction: SORT_DIRECTION;
};

export const GET_STAFF_QUERY = `${SCHOOL_URL}GET_STAFF_QUERY`;

export const useGetStaffQuery = (
  initialParams: Omit<ListPeopleArguments, 'filters' | 'type' | 'sort'> & {
    filters: GetStaffQueryFilters;
    sort?: GetStaffQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StaffSearchResult>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<StaffSearchResult>, ApiError>(
    [GET_STAFF_QUERY, params],
    ({ pageParam }) =>
      listPeople({
        ...params,
        sort: params.sort ? [params.sort] : undefined,
        pageNumber: pageParam,
        type: 'staff',
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const PARENT_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Status,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Gender,
  FilterKeys.Nationality,
  FilterKeys.InviteStatus,
  FilterKeys.OnlyEmptyEmail,
] as const;

export type GetParentsQueryFilters = {
  [FilterKeys.Date]?: [string];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.Nationality]?: Nationalities[];
  [FilterKeys.Gender]?: Genders[];
  [FilterKeys.Status]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.InviteStatus]?: InviteStatus[];
  [FilterKeys.OnlyEmptyEmail]?: number[];
};

export type GetParentsQuerySort = {
  columnTextId: 'last_name' | 'students';
  direction: SORT_DIRECTION;
};

export const GET_PARENTS_QUERY = `${SCHOOL_URL}GET_PARENTS_QUERY`;

export const useGetParentsQuery = (
  initialParams: Omit<ListPeopleArguments, 'filters' | 'type' | 'sort'> & {
    filters: GetParentsQueryFilters;
    sort?: GetParentsQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<ParentSearchResult>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ParentSearchResult>, ApiError>(
    [GET_PARENTS_QUERY, params],
    ({ pageParam }) =>
      listPeople({
        ...params,
        sort: params.sort ? [params.sort] : undefined,
        pageNumber: pageParam,
        type: 'parent',
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type ListRelationsReturn = {
  student: StatusStudentRelation;
  staff: ParentSearchResult;
  parent: StatusUserRelation;
};

export const listRelations = <U extends SchoolUserType>(
  args: ListPeopleArguments<U>,
): Promise<PagedResponse<ListRelationsReturn[U]>> => {
  return listPeople(args).then(
    (response) =>
      ({
        ...response,
        results: response.results.reduce<ListRelationsReturn[U][]>((prev, item) => {
          const prevItem = prev.length > 0 ? prev[prev.length - 1] : undefined;

          if ('registration' in item) {
            const user = item.registration.school_user_relation as ListRelationsReturn[U];

            if (!prevItem || prevItem.relation_id !== user.relation_id) {
              prev.push(user);
            }
          } else {
            if (!prevItem || prevItem.relation_id !== (item as SchoolRelation).relation_id) {
              prev.push(item as unknown as ListRelationsReturn[U]);
            }
          }

          return prev;
        }, []),
      } as PagedResponse<ListRelationsReturn[U]>),
  );
};

export type StudentWithParents = ListRelationsReturn['student'] & {
  edit_access?: boolean;
  parents: ParentSearchResult[];
};

export const isStudentWithParents = (student: any): student is StudentWithParents => {
  return 'parents' in student;
};

export const listStudentsByParents = ({
  lastItem,
  ...args
}: ListStudentsByParentsArguments): Promise<PagedResponse<StudentWithParents>> => {
  return listPeople<ParentSearchResult>({ ...args, type: 'parent' }).then(
    (response) =>
      ({
        ...response,
        results: response.results.reduce(
          (prev, item) => {
            const prevItem = prev.length > 0 ? prev[prev.length - 1] : undefined;

            if ('student' in item) {
              const user = item.student as ListRelationsReturn['student'];

              if (!prevItem || prevItem.relation_id !== user.relation_id) {
                prev.push({ ...user, parents: [item] });
              } else {
                prevItem?.parents.push(item);
              }
            }

            return prev;
          },
          lastItem ? [lastItem] : ([] as StudentWithParents[]),
        ),
      } as PagedResponse<StudentWithParents>),
  );
};

export function getGroupsByCategory(schoolId: string): Promise<GroupCategory[]> {
  return api.get(`${SCHOOL_URL}${schoolId}/groups/by-classification/`);
}

export function addSchoolAdmin(schoolId: string, userId: string) {
  const params = { user_id: userId };
  return api.post(`${SCHOOL_URL}${schoolId}/add-admin`, params);
}

export function removeSchoolRelation(schoolId: string, schoolRelationId: string) {
  return api.remove(`${SCHOOL_URL}school-relation/${schoolRelationId}`, { school_id: schoolId });
}

const checkUniqueRelationNumberToken = {};
export function checkUniqueRelationNumber(
  schoolId: string,
  number: string,
  userType: SchoolUserType,
): Promise<{ exists: boolean; user?: UserSummary } | undefined> {
  const userTypeNumeric = convertSchoolUserRoleToNumericType(userType);
  return api.get(
    `${SCHOOL_URL}${schoolId}/check-user-relation-number?number=${number}&user_type=${userTypeNumeric}`,
    {
      cancelToken: updateCancelToken(checkUniqueRelationNumberToken),
    },
  );
}

function checkSchoolEmailNoCache(
  schoolId: string,
  email: string,
  user_type: SchoolUserRole,
): Promise<{ exists: boolean; user?: UserSummary }> {
  return api.get(
    `${SCHOOL_URL}${schoolId}/check-user-relation-email?email=${email}&user_type=${user_type}`,
  );
}

const checkSchoolEmailCached = cachedRequest(checkSchoolEmailNoCache);
export const checkSchoolEmail = checkSchoolEmailCached[0];
export const clearSchoolEmailCache = checkSchoolEmailCached[1];

export type GetSchoolYearsResponse = { school_years: SchoolYear[] };

export function getSchoolYears(schoolId: string): Promise<GetSchoolYearsResponse> {
  return api.get(`${SCHOOL_DETAILS_URL}school/${schoolId}/years`);
}

export const GET_SCHOOL_YEARS_QUERY = `${SCHOOL_URL}GET_SCHOOL_YEARS_QUERY`;

export const useGetSchoolYears = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolYearsResponse>,
) => {
  return useQuery<GetSchoolYearsResponse, ApiError>(
    [GET_SCHOOL_YEARS_QUERY, schoolId],
    () => getSchoolYears(schoolId),
    options,
  );
};

export type CreateSchoolYearsResponse = {
  school_years: Pick<SchoolYear, 'id' | 'end' | 'start' | 'name'>;
};

export type CreateSchoolYearsParams = {
  schoolId: string;
  schoolYears: Partial<SchoolYear>[];
};

export const createSchoolYears = (params: CreateSchoolYearsParams) => {
  return api.post(`${SCHOOL_URL}school-years/for-school/${params.schoolId}`, {
    school_years: params.schoolYears,
  });
};

export type UpdateSchoolYearsResponse = {
  school_years: Pick<SchoolYear, 'id' | 'end' | 'start' | 'name'>;
};

export type UpdateSchoolYearsParams = {
  schoolId: string;
  schoolYears: Partial<SchoolYearUpdate>[];
};

export const updateSchoolYears = ({ schoolId, schoolYears }: UpdateSchoolYearsParams) => {
  return api.post(`${SCHOOL_URL}handle-school-years/${schoolId}`, {
    school_years: schoolYears,
  });
};

export const useCreateSchoolYearsMutation = (
  options?: RQUseMutationOptions<CreateSchoolYearsResponse, CreateSchoolYearsParams>,
): RQUseMutationResult<CreateSchoolYearsResponse, CreateSchoolYearsParams> => {
  return useMutation(createSchoolYears, {
    ...options,
  });
};

export const useUpdateSchoolYearsMutation = (
  options?: RQUseMutationOptions<UpdateSchoolYearsResponse, UpdateSchoolYearsParams>,
): RQUseMutationResult<UpdateSchoolYearsResponse, UpdateSchoolYearsParams> => {
  return useMutation(updateSchoolYears, {
    ...options,
  });
};

export function getSchoolProperties({
  schoolId,
  userType,
  showArchived = true,
}: GetSchoolPropertiesProps): Promise<GetSchoolPropertiesResponse> {
  return api.get(`${SCHOOL_DETAILS_URL}school/${schoolId}/properties`, {
    params: {
      types: [
        SchoolPropertyType.Department,
        SchoolPropertyType.House,
        SchoolPropertyType.Status,
        // Uncomment when implemented on BE
        // SchoolPropertyType.LeavingReason,

        //Age groups are not in the list but they are always sent in response in age_groups
      ].join(','),
      user_type: userType,
      show_archived: showArchived ? 1 : 0,
    },
  });
}

export const GET_SCHOOL_PROPERTIES_QUERY = `${SCHOOL_URL}GET_SCHOOL_PROPERTIES_QUERY`;

export const useGetSchoolPropertiesQuery = (
  params: GetSchoolPropertiesProps,
  options?: RQUseQueryOptions<GetSchoolPropertiesResponse>,
) => {
  return useQuery<GetSchoolPropertiesResponse, ApiError>(
    [GET_SCHOOL_PROPERTIES_QUERY, params],
    () => getSchoolProperties(params),
    options,
  );
};

export const updateAgeGroups = ({
  schoolId,
  ageGroups,
  schoolLevels,
  canDelete,
}: UpdateAgeGroupsRequest) => {
  return api.patch(`${SCHOOL_DETAILS_URL}${schoolId}/age_groups`, {
    age_groups: ageGroups,
    school_levels: schoolLevels,
    //flag is used when tuning a created a school without presets and can delete age groups
    delete: canDelete ?? undefined,
  });
};

export const useUpdateAgeGroupsMutation = (
  options?: RQUseMutationOptions<UpdateAgeGroupsResponse, UpdateAgeGroupsRequest>,
): RQUseMutationResult<UpdateAgeGroupsResponse, UpdateAgeGroupsRequest> => {
  return useMutation((params: UpdateAgeGroupsRequest) => updateAgeGroups(params), {
    ...options,
  });
};

export const createSubjects = (
  schoolId: string,
  subjects: { name: string; order_for_assessments: number }[],
) => {
  return api.post(`/groups/create-subjects`, {
    school_id: schoolId,
    subjects,
  });
};

export const updateSubjects = (
  schoolId: string,
  subjects: { subject_id?: string; name: string; order_for_assessments: number }[],
) => {
  return api.post(`/groups/handle-subjects/${schoolId}`, {
    subjects,
  });
};

export const createStatuses = (
  schoolId: string,
  statuses: { name: string; category_id: string; order: number }[],
) => {
  return api.post(`${SCHOOL_URL}properties/for-school/${schoolId}`, {
    statuses,
  });
};

export const updateStatuses = (
  schoolId: string,
  statuses: { property_id?: string; name: string; category_id: string; order: number }[],
) => {
  return api.post(`${SCHOOL_URL}handle-statuses/${schoolId}`, {
    statuses,
  });
};

export const createHouses = (schoolId: string, houses: { name: string; order: number }[]) => {
  return api.post(`${SCHOOL_URL}properties/for-school/${schoolId}`, {
    houses,
  });
};

export const updateHouses = ({ schoolId, houses }: UpdateHousesProps) => {
  return api.post(`${SCHOOL_URL}handle-houses/${schoolId}`, {
    houses,
  });
};

export const createDepartments = ({
  schoolId,
  departments,
}: CreateDepartmentsRequest): Promise<CreateDepartmentsResponse> => {
  return api.post(`${SCHOOL_URL}properties/for-school/${schoolId}`, {
    departments,
  });
};

export const useCreateDepartmentsMutation = (
  options?: RQUseMutationOptions<CreateDepartmentsResponse, CreateDepartmentsRequest>,
): RQUseMutationResult<CreateDepartmentsResponse, CreateDepartmentsRequest> => {
  return useMutation((params: CreateDepartmentsRequest) => createDepartments(params), {
    ...options,
  });
};

export const updateDepartments = ({
  schoolId,
  departments,
}: UpdateDepartmentsRequest): Promise<UpdateDepartmentsResponse> => {
  return api.post(`${SCHOOL_URL}handle-departments/${schoolId}`, {
    departments,
  });
};

export const useUpdateDepartmentsMutation = (
  options?: RQUseMutationOptions<UpdateDepartmentsResponse, UpdateDepartmentsRequest>,
): RQUseMutationResult<UpdateDepartmentsResponse, UpdateDepartmentsRequest> => {
  return useMutation((params: UpdateDepartmentsRequest) => updateDepartments(params), {
    ...options,
  });
};

export function addStudent(
  schoolId: string,
  studentId: string,
  initialRegistration: StudentRegistrationUpdate,
): Promise<{
  initial_enrollment: StudentRegistration;
  student_school_relation: StudentSchoolRelation;
}> {
  const data = {
    student_school_relation: {
      student_id: studentId,
    },
    initial_enrollment: initialRegistration,
  };

  return api.post(`${SCHOOL_URL}${schoolId}/add-student`, data);
}

export async function getStudentMembership(
  schoolId: string,
  relationId: string,
): Promise<StudentSchoolRelation> {
  const { student_school_relation } = await api.get(
    `${SCHOOL_URL}${schoolId}/student/${relationId}`,
  );
  return student_school_relation;
}

export const GET_STUDENT_MEMBERSHIP_QUERY = `${SCHOOL_URL}GET_STUDENT_MEMBERSHIP_QUERY`;

export const useGetStudentMembership = (
  params: {
    schoolId: string;
    id: string;
  },
  options?: RQUseQueryOptions<StudentSchoolRelation>,
) => {
  return useQuery<StudentSchoolRelation, ApiError>(
    [GET_STUDENT_MEMBERSHIP_QUERY, params.id, params],
    () => getStudentMembership(params.schoolId, params.id),
    options,
  );
};

export function updateStudentMembership(
  relationId: string,
  update: Partial<StudentSchoolRelation>,
): Promise<{ student_school_relation: StudentSchoolRelation }> {
  return api.patch(`${SCHOOL_URL}student-school-relation/${relationId}`, {
    student_school_relation: update,
  });
}

export function updatePrimaryContactId(
  relationId: string,
  primaryContactId: string,
): Promise<{ student_school_relation: StudentSchoolRelation }> {
  return api.patch(`${SCHOOL_URL}student-school-relation/${relationId}`, {
    student_school_relation: {},
    primary_parent_id: primaryContactId,
  });
}

export async function getParentMembership(
  schoolId: string,
  relationId: string,
): Promise<ParentSchoolRelation> {
  const { parent_school_relation } = await api.get(`${SCHOOL_URL}${schoolId}/parent/${relationId}`);
  return parent_school_relation;
}

export const GET_PARENT_MEMBERSHIP_QUERY = `${SCHOOL_URL}GET_PARENT_MEMBERSHIP_QUERY`;

export const useGetParentMembership = (
  params: {
    schoolId: string;
    id: string;
  },
  options?: RQUseQueryOptions<ParentSchoolRelation>,
) => {
  return useQuery<ParentSchoolRelation, ApiError>(
    [GET_PARENT_MEMBERSHIP_QUERY, params.id, params],
    () => getParentMembership(params.schoolId, params.id),
    options,
  );
};

export function updateParentMembership(
  relationId: string,
  update: Partial<ParentSchoolRelation>,
): Promise<{ parent_school_relation: ParentSchoolRelation }> {
  return api.patch(`${SCHOOL_URL}parent-school-relation/${relationId}`, {
    parent_school_relation: update,
  });
}

export type AddStaffResponse = {
  initial_employment: StaffRegistration;
  staff_school_relation: StaffSchoolRelation;
};

export function addStaff(
  schoolId: string,
  staffMemberId: string,
  initialRegistration: StaffRegistrationUpdate,
): Promise<AddStaffResponse> {
  const data = {
    staff_school_relation: {
      staff_id: staffMemberId,
    },
    initial_employment: initialRegistration,
  };
  return api.post(`${SCHOOL_URL}${schoolId}/add-staff`, data);
}

type AddStaffMutationParams = {
  schoolId: string;
  staffId: string;
  employment: StaffRegistrationUpdate;
};

export const useAddStaffMutation = (
  options?: RQUseMutationOptions<AddStaffResponse, AddStaffMutationParams>,
): RQUseMutationResult<AddStaffResponse, AddStaffMutationParams> => {
  return useMutation(
    (params: AddStaffMutationParams) =>
      addStaff(params.schoolId, params.staffId, params.employment),
    options,
  );
};

export async function getStaffMembership(
  schoolId: string,
  relationId: string,
): Promise<StaffSchoolRelation> {
  const { staff_school_relation } = await api.get(`${SCHOOL_URL}${schoolId}/staff/${relationId}`);
  return staff_school_relation;
}

export const GET_STAFF_MEMBERSHIP_QUERY = `${SCHOOL_URL}GET_STAFF_MEMBERSHIP_QUERY`;

export const useGetStaffMembership = (
  params: {
    schoolId: string;
    id: string;
  },
  options?: RQUseQueryOptions<StaffSchoolRelation>,
) => {
  return useQuery<StaffSchoolRelation, ApiError>(
    [GET_STAFF_MEMBERSHIP_QUERY, params.id, params],
    () => getStaffMembership(params.schoolId, params.id),
    options,
  );
};

export function updateStaffMembership(
  relationId: string,
  update: Partial<StaffSchoolRelation>,
): Promise<{ staff_school_relation: StaffSchoolRelation }> {
  return api.patch(`${SCHOOL_URL}staff-school-relation/${relationId}`, {
    staff_school_relation: update,
  });
}

export function acceptSuggestedChange(
  schoolId: string,
  change_id: string,
): Promise<{ success: boolean }> {
  return api.patch(`${SCHOOL_URL}${schoolId}/suggest-changes/${change_id}/accept`, {});
}

type UpdateSchoolMembershipParams = {
  relationId: string;
  userType: SchoolUserType;
  update: Partial<StudentSchoolRelation | StaffSchoolRelation | ParentSchoolRelation>;
};

export function updateSchoolMembership({
  userType,
  relationId,
  update,
}: UpdateSchoolMembershipParams) {
  switch (userType) {
    case 'student':
      return updateStudentMembership(relationId, update as Partial<StudentSchoolRelation>);
    case 'staff':
      return updateStaffMembership(relationId, update as Partial<StaffSchoolRelation>);
    case 'parent':
      return updateParentMembership(relationId, update as Partial<ParentSchoolRelation>);
  }
}

type UseUpdateSchoolMembershipMutationResponse = {
  student_school_relation?: StudentSchoolRelation;
  staff_school_relation?: StaffSchoolRelation;
  parent_school_relation?: ParentSchoolRelation;
};

export const useUpdateSchoolMembershipMutation = (
  options?: RQUseMutationOptions<
    UseUpdateSchoolMembershipMutationResponse,
    UpdateSchoolMembershipParams
  >,
): RQUseMutationResult<UseUpdateSchoolMembershipMutationResponse, UpdateSchoolMembershipParams> => {
  const invalidateQueries = useInvalidateSchoolMembershipQuery();

  return useMutation(
    ({ userType, update, relationId }) => {
      switch (userType) {
        case 'student':
          return updateStudentMembership(relationId, update as Partial<StudentSchoolRelation>);
        case 'staff':
          return updateStaffMembership(relationId, update as Partial<StaffSchoolRelation>);
        case 'parent':
          return updateParentMembership(relationId, update as Partial<ParentSchoolRelation>);
      }
    },
    {
      ...options,
      onSuccess: (res, vars, ...data) => {
        const { relationId, userType } = vars;
        invalidateQueries({ id: relationId, userType });

        options?.onSuccess?.(res, vars, ...data);
      },
    },
  );
};

export const useInvalidateSchoolMembershipQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (params: { id: string; userType: SchoolUserType }) => {
      switch (params.userType) {
        case 'student':
          queryClient.invalidateQueries([GET_STUDENT_MEMBERSHIP_QUERY, params.id]);
          break;
        case 'staff':
          queryClient.invalidateQueries([GET_STAFF_MEMBERSHIP_QUERY, params.id]);
          break;
        case 'parent':
          queryClient.invalidateQueries([GET_PARENT_MEMBERSHIP_QUERY, params.id]);
          break;
      }
    },
    [queryClient],
  );
};

export const CHANGE_LAST_SCHOOL = 'CHANGE_LAST_SCHOOL';

export function changeLastSchool(schoolId: string): Promise<{ success?: boolean }> {
  const data = { school_id: schoolId };
  return api.post(`${SCHOOL_URL}change-last-school`, data);
}

export const useChangeLastSchoolMutation = (
  options?: RQUseMutationOptions<{ success?: boolean }, string>,
): RQUseMutationResult<{ success?: boolean }, string> => {
  return useMutation([CHANGE_LAST_SCHOOL], (params: string) => changeLastSchool(params), {
    ...options,
  });
};

export function getInviteToAccept(inviteId: string): Promise<Invite> {
  return api.get(`${SCHOOL_URL}${encodeBase64(inviteId)}/invite-to-accept`);
}

export const GET_INVITE_TO_ACCEPT_QUERY = `${SCHOOL_URL}GET_INVITE_TO_ACCEPT_QUERY`;

export const useInviteToAcceptQuery = (inviteId: string, options?: RQUseQueryOptions<Invite>) => {
  return useQuery<Invite, ApiError>(
    [GET_INVITE_TO_ACCEPT_QUERY, inviteId],
    () => getInviteToAccept(inviteId),
    options,
  );
};

export function getInviteStatus(inviteId: string): Promise<InviteStatusData> {
  return api.get(`${SCHOOL_URL}${encodeBase64(inviteId)}/invite`);
}

type SendInviteParams = {
  schoolId: string;
  relationId: string;
};

export function sendInvite({
  schoolId,
  relationId,
}: SendInviteParams): Promise<{ success?: boolean }> {
  const params = { relation_id: relationId };

  return api.post(`${SCHOOL_URL}${schoolId}/invite`, params);
}

export const useSendInviteMutation = (
  options?: RQUseMutationOptions<{ success?: boolean }, SendInviteParams>,
): RQUseMutationResult<{ success?: boolean }, SendInviteParams> => {
  return useMutation((params: SendInviteParams) => sendInvite(params), {
    ...options,
  });
};

export function cancelInvite(schoolId: string, relationId: string): Promise<string> {
  const params = {
    relation_id: relationId,
  };

  return api.remove(`${SCHOOL_URL}${schoolId}/invite`, params);
}

export function getMiniList({
  schoolId,
  type,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  query,
  singleDate,
  filters,
  exclude,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  token,
}: MiniListArguments): Promise<PagedResponse<SimpleListResult>> {
  const group = filters?.group?.join(',');

  const params = removeObjectFalsyValues({
    page_size: pageSize,
    page_number: pageNumber,
    search_query: query,
    date_from: filters?.date?.[0],
    date_to: filters?.date?.[1] || filters?.date?.[0],
    single_date: singleDate,

    status_ids: filters?.status?.join(','),
    age_group: filters?.age_group?.join(','),
    department: filters?.department?.join(','),
    house: filters?.house?.join(','),
    subject: filters?.subject?.join(','),
    gender: filters?.gender?.join(','),
    nationality: filters?.nationality?.join(','),
    sort_by: getSortParams(sort),

    ...(exclude ? { not_group: group } : { group }),
  });

  return api.get(`${SCHOOL_URL}${schoolId}/${type}-mini-list`, { params, cancelToken: token });
}

export const GET_MINI_LIST_QUERY = `${SCHOOL_URL}GET_MINI_LIST_QUERY`;

export const MINI_LIST_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Status,
  FilterKeys.AgeGroup,
  FilterKeys.House,
  FilterKeys.Group,
] as const;

export type GetMiniListQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.Status]?: string[];
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.House]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.Nationality]?: Nationalities[];
  [FilterKeys.Gender]?: Genders[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.TutorGroup]?: string[];
  [FilterKeys.SingleDate]?: string[];
};

export type GetMiniListQueryParams = Omit<MiniListArguments, 'filters'> & {
  filters: GetMiniListQueryFilters;
};

export const generateGetMiniListQueryKey = (v: GetMiniListQueryParams) => [GET_MINI_LIST_QUERY, v];

export const useGetMiniListQuery = (
  initialParams: GetMiniListQueryParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<SimpleListResult>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<SimpleListResult>, ApiError>(
    generateGetMiniListQueryKey(params),
    ({ pageParam }) => getMiniList({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const GET_ELIGIBLE_RELATIONS_QUERY = `${SCHOOL_URL}GET_ELIGIBLE_RELATIONS_QUERY`;

export function getEligibleRelations({
  schoolId,
  status,
  not_status,
  ...params
}: GetEligibleRelationsProps): Promise<{ relation_ids: string[] }> {
  return api.post(
    `${SCHOOL_URL}${schoolId}/eligible-relations`,
    removeObjectEmptyArrays({ ...params, status_ids: status, not_status_ids: not_status }),
  );
}

export const useGetEligibleRelationsQuery = (
  params: GetEligibleRelationsProps,
  options?: RQUseQueryOptions<{ relation_ids: string[] }>,
) => {
  return useQuery<{ relation_ids: string[] }, ApiError>(
    [GET_ELIGIBLE_RELATIONS_QUERY, params],
    () => getEligibleRelations(params),
    {
      ...options,
    },
  );
};

export const useGetEligibleRelationsMutation = (
  options?: RQUseMutationOptions<{ relation_ids: string[] }, GetEligibleRelationsProps>,
): RQUseMutationResult<{ relation_ids: string[] }, GetEligibleRelationsProps> => {
  return useMutation((params: GetEligibleRelationsProps) => getEligibleRelations(params), options);
};

export const changeDefaultValuesForProperties = ({
  schoolId,
  properties,
}: ChangeDefaultValuesForPropertiesProps) => {
  return api.post(`${SCHOOL_URL}/change-default-values-for-properties/${schoolId}`, { properties });
};

type SendBulkInvitesParams = {
  schoolId: string;
  query?: string;
  relation_ids?: string[];
  show_editable?: boolean;
  filters?: {
    [FilterKeys.Date]?: [string];
    [FilterKeys.AgeGroup]?: string[];
    [FilterKeys.Status]?: string[];
    [FilterKeys.Gender]?: Genders[];
    [FilterKeys.House]?: string[];
    [FilterKeys.Department]?: string[];
    [FilterKeys.Nationality]?: Nationalities[];
    [FilterKeys.Group]?: string[];
    [FilterKeys.Subject]?: string[];
    [FilterKeys.InviteStatus]?: InviteStatus[];
    [FilterKeys.TutorGroup]?: InviteStatus[];
  };
};

type SendBulkInvitesResponse = {
  success_results: {
    success: string;
    status: number;
    relation_id: string;
  }[];
  failed_results: { status: number; relation_id: string }[];
};

const sendBulkInvites = async ({
  schoolId,
  filters,
  query,
  relation_ids,
  show_editable,
}: SendBulkInvitesParams): Promise<SendBulkInvitesResponse> => {
  const params = removeObjectUndefinedNullValues({
    query,
    relation_ids,
    show_editable,
    date: filters?.date?.[0],
    status_ids: filters?.status?.join(','),
    age_group: filters?.age_group?.join(','),
    department: filters?.department?.join(','),
    house: filters?.house?.join(','),
    subject: filters?.subject?.join(','),
    gender: filters?.gender?.join(','),
    nationality: filters?.nationality?.join(','),
    group: filters?.group?.join(','),
    invite_status: filters?.invite_status?.join(','),
    tutor_group: filters?.tutor_group?.join(','),
  });

  return api.post(`${SCHOOL_URL}${schoolId}/bulk-invite`, params);
};

export const useSendBulkInvitesMutation = (
  options?: RQUseMutationOptions<SendBulkInvitesResponse, SendBulkInvitesParams>,
): RQUseMutationResult<SendBulkInvitesResponse, SendBulkInvitesParams> => {
  return useMutation((params: SendBulkInvitesParams) => sendBulkInvites(params), {
    ...options,
  });
};

export const UPDATE_SCHOOL_YEAR_PERIODS_MUTATION = `${SCHOOL_URL}UPDATE_SCHOOL_YEAR_PERIODS_MUTATION`;

export function updateSchoolYearPeriods({
  schoolId,
  schoolYearId,
  ...params
}: UpdateSchoolYearPeriodsRequest): Promise<{ success: string; id: string }> {
  return api.patch(`${SCHOOL_URL}${schoolId}/years/${schoolYearId}/period-groups`, { ...params });
}

export const useUpdateSchoolYearPeriodsMutation = (
  options?: RQUseMutationOptions<{ success: string; id: string }, UpdateSchoolYearPeriodsRequest>,
): RQUseMutationResult<{ success: string; id: string }, UpdateSchoolYearPeriodsRequest> => {
  return useMutation(
    [UPDATE_SCHOOL_YEAR_PERIODS_MUTATION],
    (params: UpdateSchoolYearPeriodsRequest) => updateSchoolYearPeriods(params),
    {
      ...options,
    },
  );
};

export type CreateSchoolWithPropertiesParams = {
  school: SchoolCreateProps & { type: SchoolType; education_system?: SchoolEducationSystem };
  school_years: Pick<SchoolYear, 'end' | 'start' | 'name'>[];
  properties: Pick<SchoolProperty, 'type' | 'order' | 'name' | 'category'>[];
  school_levels: Pick<SchoolLevel, 'order' | 'name'>[];
  age_groups: (Pick<AgeGroup, 'order' | 'name'> & { level_id?: string | null })[];
  subjects: Pick<GroupSubject, 'name'>[];
  accesses: (Pick<UserRole, 'name' | 'description'> & {
    permission_to_assign: string[];
    permissions: string[];
  })[];
  attendances: Omit<AttendanceCode, 'id'>[];
  grades: AssessmentsGradeUpdate[];
  conducts: ConductTypeCreate[];
  // TODO: unblock TR-6124
  // leaving_reasons: Pick<SchoolProperty, 'name' | 'order'>[];
  // emails: NotificationInternalEmails;
};

export const createSchoolWithProperties = (params: CreateSchoolWithPropertiesParams) => {
  return api.post(`${SCHOOL_URL}create-school-with-props`, params);
};

export type CreateSchoolWithPropertiesResponse = {
  school: CreateSchoolResponse;
};

export const useCreateSchoolWithPropertiesMutation = (
  options?: RQUseMutationOptions<
    CreateSchoolWithPropertiesResponse,
    CreateSchoolWithPropertiesParams
  >,
): RQUseMutationResult<CreateSchoolWithPropertiesResponse, CreateSchoolWithPropertiesParams> => {
  return useMutation(
    (params: CreateSchoolWithPropertiesParams) => createSchoolWithProperties(params),
    {
      ...options,
    },
  );
};

export const GET_SCHOOLS_QUERY = `${SCHOOL_URL}GET_SCHOOLS_QUERY`;

export type GetSchoolsParams = {
  search: string;
  country?: Countries;
  pageNumber?: number;
  pageSize?: number;
};

export type GetSchoolsResponse = PagedResponse<School>;

export const getSchools = ({
  pageNumber,
  pageSize,
  search,
  country,
}: GetSchoolsParams): Promise<GetSchoolsResponse> => {
  return api.get(`${SCHOOL_URL}list`, {
    params: removeObjectUndefinedNullValues({
      page_size: pageSize || 16,
      page_number: pageNumber,
      name: search,
      country: country !== undefined ? Countries[country] : undefined,
    }),
  });
};

export const useGetSchoolsQuery = (
  params: GetSchoolsParams,
  options?: RQUseInfiniteQueryOptions<GetSchoolsResponse>,
) => {
  return useInfiniteQuery<GetSchoolsResponse, ApiError>(
    [GET_STUDENTS_QUERY, params],
    ({ pageParam }) =>
      getSchools({
        ...params,
        pageNumber: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );
};

export function getDuplicates(
  schoolId: string,
  data: UserForDuplicatesCheck[],
): Promise<DuplicatesForUser[]> {
  return api.post(`${SCHOOL_URL}${schoolId}/duplicates`, data);
}

export const GET_DUPLICATES_QUERY = `${SCHOOL_URL}/GET_DUPLICATES_QUERY`;

export const useGetDuplicatesQuery = (
  params: { schoolId: string; data: UserForDuplicatesCheck[] },
  options?: RQUseQueryOptions<DuplicatesForUser[]>,
) => {
  return useQuery<DuplicatesForUser[], ApiError>(
    [GET_DUPLICATES_QUERY, params],
    () => getDuplicates(params.schoolId, params.data),
    {
      ...options,
    },
  );
};

export const mergeStudents = ({ schoolId, params }: MergeStudentsRequest) => {
  return api.post(`${SCHOOL_URL}${schoolId}/students/merge`, params);
};

export const useMergeStudentsMutation = (
  options?: RQUseMutationOptions<{ success: boolean }, MergeStudentsRequest>,
): RQUseMutationResult<{ success: boolean }, MergeStudentsRequest> => {
  const queryClient = useQueryClient();

  return useMutation((params: MergeStudentsRequest) => mergeStudents(params), {
    ...options,
    onSuccess: (res, vars, ...data) => {
      const { relation_id_to_be_preserved } = vars.params;

      queryClient.invalidateQueries([GET_STAFF_MEMBERSHIP_QUERY, relation_id_to_be_preserved]);
      queryClient.invalidateQueries([GET_STUDENT_MEMBERSHIP_QUERY, relation_id_to_be_preserved]);
      queryClient.invalidateQueries([GET_PARENT_MEMBERSHIP_QUERY, relation_id_to_be_preserved]);

      options?.onSuccess?.(res, vars, ...data);
    },
  });
};

type SendDemoSchoolFeedbackRequest = {
  schoolId: string;
  email: string;
  question: string;
};

const sendDemoSchoolFeedback = (params: SendDemoSchoolFeedbackRequest) => {
  return api.get(`${SCHOOL_URL}${params.schoolId}`);
};

export const useSendDemoSchoolFeedbackMutation = (
  options?: RQUseMutationOptions<{ success: boolean }, SendDemoSchoolFeedbackRequest>,
): RQUseMutationResult<{ success: boolean }, SendDemoSchoolFeedbackRequest> => {
  return useMutation(sendDemoSchoolFeedback, {
    ...options,
  });
};

type GetDemoSchoolCredentialsRequest = {
  school_type: DemoSchoolType;
};

type GetDemoSchoolCredentialsResponse = {
  email: string;
  password: string;
};

const getDemoSchoolCredentials = ({ school_type }: GetDemoSchoolCredentialsRequest) => {
  return api.get(`/profile/demo-profile?school_type=${school_type}`);
};

export const useGetDemoSchoolCredentialsMutation = (
  options?: RQUseMutationOptions<GetDemoSchoolCredentialsResponse, GetDemoSchoolCredentialsRequest>,
): RQUseMutationResult<GetDemoSchoolCredentialsResponse, GetDemoSchoolCredentialsRequest> => {
  return useMutation(getDemoSchoolCredentials, {
    ...options,
  });
};

export const markStudentsNotDuplicates = ({
  schoolId,
  params,
}: MarkStudentsNotDuplicateRequest) => {
  return api.post(`${SCHOOL_URL}${schoolId}/duplicates/mark-not-duplicates`, params);
};

export const useMarkStudentsNotDuplicates = (
  options?: RQUseMutationOptions<{ success: boolean }, MarkStudentsNotDuplicateRequest>,
): RQUseMutationResult<{ success: boolean }, MarkStudentsNotDuplicateRequest> => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: MarkStudentsNotDuplicateRequest) => markStudentsNotDuplicates(params),
    {
      ...options,
      onSuccess: (res, vars, ...data) => {
        const { relation_a_id, relation_b_id } = vars.params;

        queryClient.invalidateQueries([GET_STAFF_MEMBERSHIP_QUERY, relation_a_id]);
        queryClient.invalidateQueries([GET_STAFF_MEMBERSHIP_QUERY, relation_b_id]);
        queryClient.invalidateQueries([GET_STUDENT_MEMBERSHIP_QUERY, relation_a_id]);
        queryClient.invalidateQueries([GET_STUDENT_MEMBERSHIP_QUERY, relation_b_id]);
        queryClient.invalidateQueries([GET_PARENT_MEMBERSHIP_QUERY, relation_a_id]);
        queryClient.invalidateQueries([GET_PARENT_MEMBERSHIP_QUERY, relation_b_id]);
        options?.onSuccess?.(res, vars, ...data);
      },
    },
  );
};

export type SchoolReEnrollmentResponse = {
  enabled: boolean;
  status_ids: string[];
  rollover_year_ids: string[];
};

export const GET_SCHOOL_RE_ENROLLMENT_STATUS = `${SCHOOL_URL}GET_SCHOOL_RE_ENROLLMENT_STATUS`;

export function getSchoolReEnrollmentStatus(schoolId: string): Promise<SchoolReEnrollmentResponse> {
  return api.get(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/re-enrollment`);
}

export const useGetSchoolReEnrollmentStatus = (
  schoolId: string,
  options?: RQUseQueryOptions<SchoolReEnrollmentResponse>,
) => {
  return useQuery<SchoolReEnrollmentResponse, ApiError>(
    [GET_SCHOOL_RE_ENROLLMENT_STATUS, schoolId],
    () => getSchoolReEnrollmentStatus(schoolId),
    options,
  );
};

export type SchoolLeavingStatusResponse = {
  leaving_status_id: string;
};

export const GET_SCHOOL_LEAVE_STATUS = `${SCHOOL_URL}GET_SCHOOL_LEAVE_STATUS`;

export function getSchoolLeavingStatus(schoolId: string): Promise<SchoolLeavingStatusResponse> {
  return api.get(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/leaving-school`);
}

export const useGetSchoolLeavingStatus = (
  schoolId: string,
  options?: RQUseQueryOptions<SchoolLeavingStatusResponse>,
) => {
  return useQuery<SchoolLeavingStatusResponse, ApiError>(
    [GET_SCHOOL_LEAVE_STATUS, schoolId],
    () => getSchoolLeavingStatus(schoolId),
    options,
  );
};

export type UpdateSchoolReEnrollmentRequestParams = {
  schoolId: string;
  enabled: boolean;
  status_ids?: string[];
};

export function changeSchoolReEnrollmentStatus({
  schoolId,
  ...params
}: UpdateSchoolReEnrollmentRequestParams): Promise<SchoolReEnrollmentResponse> {
  return api.patch(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/re-enrollment`, params);
}

export const useUpdateSchoolReEnrollmentStatusMutation = (
  options?: RQUseMutationOptions<SchoolReEnrollmentResponse, UpdateSchoolReEnrollmentRequestParams>,
): RQUseMutationResult<SchoolReEnrollmentResponse, UpdateSchoolReEnrollmentRequestParams> => {
  return useMutation(
    [GET_SCHOOL_RE_ENROLLMENT_STATUS],
    (params: UpdateSchoolReEnrollmentRequestParams) => changeSchoolReEnrollmentStatus(params),
    {
      ...options,
    },
  );
};

export type UpdateSchoolLeavingRequestParams = {
  schoolId: string;
  status_id: string;
};

type UpdateSchoolLeavingStatusResponse = {
  success: string;
};

export function changeSchoolLeavingStatus({
  schoolId,
  ...params
}: UpdateSchoolLeavingRequestParams): Promise<UpdateSchoolLeavingStatusResponse> {
  return api.post(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/leaving-school`, params);
}

export const useUpdateSchoolLeavingStatusMutation = (
  options?: RQUseMutationOptions<
    UpdateSchoolLeavingStatusResponse,
    UpdateSchoolLeavingRequestParams
  >,
): RQUseMutationResult<UpdateSchoolLeavingStatusResponse, UpdateSchoolLeavingRequestParams> => {
  return useMutation(
    (params: UpdateSchoolLeavingRequestParams) => changeSchoolLeavingStatus(params),
    {
      ...options,
    },
  );
};

export type LeavingReasonCreateUpdateRequest = {
  id?: string;
  title: string;
  order: number;
  archived: boolean;
  type: LeavingReasonType;
};

export type UpdateSchoolLeavingReasonsRequest = {
  schoolId: string;
  leaving_reasons: LeavingReasonCreateUpdateRequest[];
};

export type UpdateSchoolLeavingReasonsResponse = {
  leaving_reasons: LeavingReason[];
};

export type GetSchoolLeavingReasonsResponse = {
  leaving_reasons: LeavingReason[];
};

function getSchoolLeavingReasons(schoolId: string): Promise<GetSchoolLeavingReasonsResponse> {
  return api.get(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/leaving-school/reasons/`);
}

export const GET_SCHOOL_LEAVING_REASONS = `${SCHOOL_URL}/GET_SCHOOL_LEAVING_REASONS`;

export const useGetSchoolLeavingReasons = (
  params: { schoolId: string },
  options?: RQUseQueryOptions<GetSchoolLeavingReasonsResponse>,
) => {
  return useQuery<GetSchoolLeavingReasonsResponse, ApiError>(
    [GET_SCHOOL_LEAVING_REASONS, params],
    () => getSchoolLeavingReasons(params.schoolId),
    {
      ...options,
    },
  );
};

const updateSchoolLeavingReasons = ({
  schoolId,
  leaving_reasons,
}: UpdateSchoolLeavingReasonsRequest): Promise<UpdateSchoolLeavingReasonsResponse> => {
  return api.post(`${SCHOOL_DETAILS_URL}school/${schoolId}/settings/leaving-school/reasons/`, {
    leaving_reasons,
  });
};

export const useUpdateLeavingReasonsMutation = (
  options?: RQUseMutationOptions<
    UpdateSchoolLeavingReasonsResponse,
    UpdateSchoolLeavingReasonsRequest
  >,
): RQUseMutationResult<UpdateSchoolLeavingReasonsResponse, UpdateSchoolLeavingReasonsRequest> => {
  return useMutation(
    (params: UpdateSchoolLeavingReasonsRequest) => updateSchoolLeavingReasons(params),
    {
      ...options,
    },
  );
};

export const useGetMembership = (
  userType: UserType,
  params: { id: string; schoolId: string },
  { enabled }: { enabled: boolean },
) => {
  const { id, schoolId } = params;
  const studentMembership = useGetStudentMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'student' && !!schoolId && !!id,
  });
  const staffMembership = useGetStaffMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'staff' && !!schoolId && !!id,
  });
  const parentMembership = useGetParentMembership(params, {
    refetchOnMount: 'always',
    enabled: enabled && userType === 'parent' && !!schoolId && !!id,
  });

  switch (userType) {
    case 'staff':
      return staffMembership;
    case 'student':
      return studentMembership;
    case 'parent':
      return parentMembership;
    default:
      return {
        data: undefined,
        isLoading: false,
        isFetching: false,
        error: undefined,
      };
  }
};
