import { Box, Icon, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
  SelectOptionsArchivedIcon,
} from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { DropdownIcon, Spin } from '@schooly/style';
import { FC, useCallback, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type SchoolWithdrawalStatusContentProps = {
  schoolId: string;
  selectedStatusId: string;
  loading: boolean;
  onSelectId: (v: string) => void;
} & Omit<DropdownSelectProps, 'renderContent' | 'children'>;

export const SchoolWithdrawalStatusContent: FC<SchoolWithdrawalStatusContentProps> = ({
  schoolId,
  selectedStatusId,
  loading,
  onSelectId,
  ...dropdownProps
}) => {
  const { $t } = useIntl();

  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId ?? '',
    userType: SchoolUserRole.Student,
    showReEnrollmentProperties: true,
  });

  const formerStatusOptions = useMemo(
    () =>
      activePropertiesMap.status
        .filter((s) => s.category?.name === 'Former' && !s.archived)
        .map((s) => ({
          value: s.id,
          label: s.name,
        })),
    [activePropertiesMap.status],
  );

  const currentStatusLabel = useMemo(
    () => formerStatusOptions.find((option) => option.value === selectedStatusId)?.label,
    [formerStatusOptions, selectedStatusId],
  );

  const hasActiveStatuses = !!formerStatusOptions.length;

  const dropdown = useRef<DropdownSelect>(null);

  const renderContent = useCallback(() => {
    if (!formerStatusOptions.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return formerStatusOptions.map((status) => (
      <DropdownSelectRow
        isSelected={status.value === selectedStatusId}
        onClick={() => {
          onSelectId(status.value);
          dropdown.current?.close();
        }}
      >
        {status.label}
      </DropdownSelectRow>
    ));
  }, [formerStatusOptions, selectedStatusId, onSelectId]);

  return (
    <Box width="100%" maxWidth={500}>
      <DropdownSelect
        ref={dropdown}
        {...dropdownProps}
        hasValues={hasActiveStatuses}
        disabled={!hasActiveStatuses}
        renderContent={renderContent}
        renderRightIcon={() =>
          !hasActiveStatuses ? (
            <SelectOptionsArchivedIcon
              isAdmin
              type={FilterKeys.LeavingReason}
              values={{
                statusName: $t({ id: 'school-tabs-Statuses-Status-Former' }),
              }}
              path="/settings/statuses"
            />
          ) : (
            <Icon>{loading ? <Spin /> : <DropdownIcon />}</Icon>
          )
        }
        sx={{
          '.right-icon': { opacity: '1 !important' },
          '.svg-icon': { transform: 'rotate(0)' },
        }}
        borderOnHover
      >
        {(opened) => <Typography variant="h3">{currentStatusLabel}</Typography>}
      </DropdownSelect>
    </Box>
  );
};
